'use strict';

import "../styles/loader.scss"

window.$ = window.jQuery = require("jquery");
let LxCommunicator = require("./lxCommunicator.js"),
    Translations = require("../resources/translations/wi/translations.js")

var PreLoadLogin = function () {
    var WEB_TOKEN_TYPE = 2,
        // the token type to use for logging in from the web.
        DEV_PUBLIC_KEY = "-----BEGIN CERTIFICATE-----MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDTEBiUtYNiGlrRZm184J5buRR/MYPNMR0eIPfOseIskiJkvDqXQ75YlU+3M6/zEAy1IVunc5yPoVFMESg4C+mCXrtLnJxTuSucEmpGMgycoDCZio/maOKHRJQtoTAYQJ1C55N2OmFDAy5nHDfpQX1wDo79o1TZo7xa+mrWRBOjHQIDAQAB-----END CERTIFICATE-----"; // ------------------------------------------------------------------------------------------------------------
    // --------------------------------        Translation        -------------------------------------------------
    // ------------------------------------------------------------------------------------------------------------

    var Translator = function () {
        var lang = navigator.languages && navigator.languages.length > 0 ? navigator.languages[0] : navigator.language || navigator.userLanguage;

        if (!lang || lang === "") {
            lang = "en-gb";
        } else {
            lang = lang.toLowerCase();
        } // do not split the following languages, because the translated strings are named pt-br and zh-cn
        // otherwise the language pt and zh can't be found


        if (lang !== "pt-br" && lang !== "zh-cn") {
            lang = lang.split("-")[0];
        }

        var localizedStrings; // Check if the language is translated
        // If the language is not supported English and dev (German) is used in this order

        if (Translations[lang]) {
            localizedStrings = Translations[lang];
        } else {
            localizedStrings = Translations.en || Translations.dev;
        }

        function localize(string, formats) {
            // First we check if the string is translated
            // use development string if not translated already -> otherwise just the ID will be shown in the preload login.
            var stringComponents = string.split(".");

            if (stringComponents.length > 1) {
                string = _getTranslatedString(stringComponents);
            } else if (localizedStrings[string]) {
                string = localizedStrings[string];
            } else if (Translations.en && Translations.en[string]) {
                string = Translations.en[string];
            } else {
                string = Translations.dev[string];
            } // We then replace the placeholders


            for (var key in formats) {
                if (!formats.hasOwnProperty(key)) {
                    continue;
                }

                var searchString = `__${key}__`;

                while (string.indexOf(searchString) !== -1) {
                    // We replace every same placeholder with the same value
                    string = string.replace(searchString, formats[key]);
                }
            }

            return string;
        }

        function _getTranslatedString(stringComponents) {
            var objKey = stringComponents.shift(),
                string;
            var translatedObject = localizedStrings[objKey]; // Check if the string is translated
            // If the language is not supported English and dev (German) is used in this order

            if (!translatedObject) {
                if (Translations.en && Translations.en[objKey]) {
                    translatedObject = Translations.en[objKey];
                } else {
                    translatedObject = Translations.dev[objKey];
                }
            }

            if (translatedObject) {
                for (var subIDKey in stringComponents) {
                    if (stringComponents.hasOwnProperty(subIDKey)) {
                        var subID = stringComponents[subIDKey];
                        translatedObject = translatedObject[subID];
                    }
                }

                if (typeof translatedObject === "string") {
                    string = translatedObject;
                } else {
                    string = stringComponents.join(".");
                }
            }

            return string;
        }

        return {
            localize: localize
        };
    }(); // ------------------------------------------------------------------------------------------------------------
    // ------------------------------        PreLoad Login        -------------------------------------------------
    // ------------------------------------------------------------------------------------------------------------


    var ErrorCodes = {
        UNAVAILABLE: 0,
        UNAUTHORIZED: 401,
        ACCESS_BLOCKED: 403,
        NO_PERMISSION: 412,
        MINISERVER_OOS: 503
    }; // Setting up all the variables

    var _autologin = false;

    if (readFromLocalStorage("autologin")) {
        _autologin = JSON.parse(readFromLocalStorage("autologin"));
    } // The appVersion will be adopted automatically!


    var _appInfoObject = {
        appVersion: `${$$SEM_VERS$$} (${$$BUILD_DATE$$})`
    };

    var _body = $("body");

    var _loginContainer = _body.find(".login-container");

    var _loginWindow = _body.find(".login-container__login-window");

    var _iconContainer = _body.find(".login-window__icon-container");

    var _title = _loginWindow.find(".login-window__title");

    var _form = _loginWindow.find(".login-window__login-form");

    var _loaderContainer = _loginWindow.find(".login-window__loader-container");

    var _loginForm = {
        form: _form,
        username: _form.find(".login-form__username"),
        password: _form.find(".login-form__password"),
        connect: _form.find(".login-form__connect-button")
    };

    var _msVersionLbl = _body.find(".version-container__ms-version");

    var _appVersionLbl = _body.find(".version-container__app-version");

    var _usr = null;
    var _pwd = null;
    var _loaderIsLoading = false;

    var _ipMetaTag = $("meta[name='host'],meta[address]");

    var API_KEY_CMD = "jdev/cfg/apiKey",
        DEV_UUID_KEY = "lxWebinterfaceDeviceUuid",
        PUB_KEY_UUID = "lxPublicKey"; // This hides the indicator and enables all input fields


    var params = new URLSearchParams(document.location.search);
    var providedUser = params.get("username");
    var providedToken = params.get("token");
    var providedPassword = params.get("password");
    var providedTrustToken = params.get("trustToken");

    providedUser && _loginForm.username.val(providedUser);
    providedPassword && _loginForm.password.val(providedPassword);
    if (providedUser && (providedToken || providedPassword || providedTrustToken)) {
        console.log("AUTOLOGIN due to provided creds! user=" + providedUser + ", pass=" + !!providedPassword + ", token=" + !!providedToken + ", trustToken=" + !!providedTrustToken);
        setAutologin(true);
    }


    unblockInput();

    if (isCloudDNS()) {
        window.CLOUD_DNS_MS_ADDRESS = _ipMetaTag.attr("content") || _ipMetaTag.attr("address");
    } // We will set username in focus, so we directly can start typing...

    _loginForm.username.focus(); // setting Version to _appVersionLbl


    _appVersionLbl.text(Translator.localize("webinterface") + ": " + _appInfoObject.appVersion); // Translating the form


    _title.text(Translator.localize("webinterface"));

    _loginForm.username.attr("placeholder", Translator.localize("pre-load-login.username-placeholder"));

    _loginForm.password.attr("placeholder", Translator.localize("pre-load-login.password-placeholder"));

    var wiIsLoaded = function wiIsLoaded() {
        window._WI_LOADED = true;
    }; // Simply request the version from the Miniserver


    LxCommunicator.requestValue(isCloudDNS() ? "http://" + window.CLOUD_DNS_MS_ADDRESS + "/" : "", API_KEY_CMD).then(function (value) {
        // Check if we are not connected via HTTPS if not try to build a valid HTTPS Url and redirect to that url
        try {
            if (location.protocol.indexOf("https:") === -1 && LxCommunicator.supportsHTTPSRedirect(value.version) && location.hostname !== "localhost") {
                // Yes, https is available
                if (value.httpsStatus === 1) {
                    // Build the remoteConnect HTTPS url
                    var host, url;

                    if (value.local && value.address) {
                        host = value.address;
                    } else {
                        host = location.hostname;
                    }

                    url = "https://" + host.replace(/\./g, "-") + "." + value.snr.replace(/:/g, "") + ".dyndns.loxonecloud.com/";if (value.certTLD && value.certTLD !== "com") {
                        url = url.replace(".com/", "." + value.certTLD + "/");
                    } // Probe if we can load data using this url

                    LxCommunicator.requestValue(url, API_KEY_CMD).done(function (probRes) {
                        // Check if its still the same Miniserver
                        if (value.snr === probRes.snr) {
                            // Redirect
                            location.href = url;
                        } else {
                            wiIsLoaded();
                        }
                    }, wiIsLoaded);
                } else {
                    wiIsLoaded();
                }
            } else {
                wiIsLoaded();
            }
        } catch (e) {
            // Don't do anything if the automatic HTTPS redirect fails
            wiIsLoaded();
        }

        _msVersionLbl.text(Translator.localize("miniserver.miniserver") + ": " + value.version);

        if (value.isInTrust) {
            _loginForm.username.attr("placeholder", Translator.localize("pre-load-login.username-placeholder") + "@" + Translator.localize("controls.intercom.about.audio.host"));
        }
    }, wiIsLoaded); // Loader:

    var Loader = {
        loadJS: function (s, u, p, t, tt) {
            _title.hide();

            _iconContainer.hide();

            _form.hide();

            _loaderContainer.removeClass("hidden"); // Do not change the order!
            // CSS files may only have a path property

            let lazyScripts = $("lazyScript"),
                files = lazyScripts.map(function() {
                return $(this).attr("src");
            }).toArray();
            lazyScripts.remove();
            var error;
            var filesCnt = files.length;
            var loadingCnt = 1;

            var title = _loaderContainer.find(".text-container__title");

            var subtitle = _loaderContainer.find(".text-container__subtitle"); // Setting the texts


            var titleString = Translator.localize("pre-load-login.loading-script-out-of-script", {
                loadingCnt: loadingCnt,
                scriptsCnt: filesCnt
            });
            title.text(titleString);
            subtitle.text(Translator.localize("pre-load-login.long-loading-description"));

            function loadNext(filePath) {
                if (error) {
                    return;
                }
                var titleString = Translator.localize("pre-load-login.loading-script-out-of-script", {
                    loadingCnt: loadingCnt++,
                    scriptsCnt: filesCnt
                });
                title.text(titleString);
                var url = filePath + "?v=" + encodeURIComponent(_appInfoObject.appVersion),
                    element;

                if (filePath.endsWith(".css")) {
                    element = $('<link rel="stylesheet">');
                } else {
                    element = $('<script></script>');
                } // Here we add all the attributes, mainly for require.js

                element[0].onload = function () {
                    element[0].onerror = null;

                    if (files.length > 0) {
                        loadNext(files.shift());
                    } else {
                        bootKerberos();
                    }
                };

                element[0].onerror = function (msg, url, lineNo, columnNo, e) {
                    // Here we create the real path, so we only show the important information
                    var path = url.replace(location.origin, "").split("?")[0];
                    showErrorForFile(path, e);
                };

                element.appendTo("head");

                if (filePath.endsWith(".css")) {
                    element[0].href = url;
                } else {
                    element[0].src = url;
                }

                function bootKerberos() {
                    triggerEvent(document.body, "DOMContentLoaded");
                    triggerEvent(document.body, "load"); // We directly make a URL Start to the miniserver

                    var address = isCloudDNS() ? window.CLOUD_DNS_MS_ADDRESS : window.location.host;
                    var url = "loxone://ms?";

                    if (getMiniserverFromArchive(s)) {
                        url += "mac=" + s;
                    } else {
                        url += "host=" + encodeURIComponent(address);
                    }

                    var reg = CompChannel.on(CCEvent.REMOVE_PRELOAD_LOGIN, function () {
                        // Clearing the Body
                        _loginContainer.remove(); // We remove the pre-load-login css, so we don't override the styles


                        $("#pre-load-login-css").remove(); // Removing wi-adds and cacheBuster

                        var scripts = $('script[src^="scripts/wi-adds.js"]');
                        scripts.remove();
                        reg();
                    }); // pass in the username plus the password or token.

                    url += "&usr=" + encodeURIComponent(u);

                    if (t) {
                        url += "&token=" + encodeURIComponent(t);
                    } else {
                        url += "&pwd=" + encodeURIComponent(p);
                    }
                    if (tt) {
                        url += "&trustToken=" + encodeURIComponent(tt);
                    }

                    window.handleOpenURL(url);
                }

                function showErrorForFile(filePath, e) {
                    error = e;
                    console.error("Error: " + e);
                    var filePathComponents = filePath.split("/");
                    title.text(Translator.localize("pre-load-login.error-script", {
                        errorScript: filePathComponents[filePathComponents.length - 1]
                    }));
                    subtitle.html('<a href=".">' + Translator.localize("pre-load-login.try-again") + '</a>');
                    title.css("color", "red");
                }
            }

            loadNext(files.shift());
        }
    }; // Helper:

    function checkLogin(username, password) {
        var url,
            snr,
            msObj,
            forceFetchPk,
            suppEncryption,
            suppTokens,
            token,
            devInfo = getDeviceInfo(),
            devUuid = getDeviceUuid(); // username and password only are set when an autologin occure

        if (!username) {
            username = _loginForm.username.val();
        }

        if (!password) {
            password = _loginForm.password.val();
        }

        url = isCloudDNS() ? "http://" + window.CLOUD_DNS_MS_ADDRESS + "/" : "";
        blockInput();
        LxCommunicator.requestValue(url, API_KEY_CMD).then(function succ(value) {
            snr = value.snr.replace(/\:/g, "");
            msObj = getMiniserverFromArchive(snr); // Check, if the dev PK is used and the miniserver supports generating a new one

            forceFetchPk = msObj && shouldForceFetchPk(msObj.publicKey, value.version);
            suppTokens = LxCommunicator.supportsTokens(value.version);
            suppEncryption = LxCommunicator.supportsEncryption(value.version);

            if (suppTokens || suppEncryption) {
                if (providedToken || providedTrustToken) {
                    return;
                }
                // we need a public key, everything's encrypted now.
                return _getPublicKey(url, snr, forceFetchPk).then(function (pubKey) {
                    LxCommunicator.setPublicKey(pubKey);

                    if (suppTokens) {
                        return LxCommunicator.requestToken(url, username, password, WEB_TOKEN_TYPE, devInfo, devUuid, value.version).then(function (tkObj) {
                            token = tkObj.token; // no need for validUntil and a otSalt.

                            return token;
                        });
                    } else {
                        return LxCommunicator.authViaPassword(url, username, password, value.key, value.version);
                    }
                });
            } else {
                // No Public key. No encryption, regular authentication
                return LxCommunicator.authViaPassword(url, username, password, value.key, value.version);
            }
        }).then(function done() {
            if (!_loaderIsLoading) {
                setAutologin(true);
                Loader.loadJS(snr, username, password, token, providedTrustToken);
            } else {
                console.log("Loader is already loading!");
            }

            _loaderIsLoading = true;
        }, showError);
    }

    /**
     * Will try to load the public key for this miniserver. If not stored locally, it'll load it from the Miniserver.
     * @param url
     * @param snr
     * @param forceFetch
     * @private
     */


    function _getPublicKey(url, snr, forceFetch) {
        var promise,
            pk,
            msObj = getMiniserverFromArchive(snr);

        if (msObj) {
            pk = msObj.publicKey;
        }

        if (pk && !forceFetch) {
            promise = $.when(pk);
        } else {
            LxCommunicator.setPublicKey(null); // Reset the public key

            promise = LxCommunicator.getPublicKey(url);
        }

        return promise;
    }

    /**
     * Will return a most likely unique (uniqueness is only required per Miniserver) identifier for this webinterface.
     * It should be the same identifier used inside the app later on for requesting other token types (user managment, ..)
     * @returns {string}
     */


    function getDeviceUuid() {
        var uuid = readFromLocalStorage(DEV_UUID_KEY);

        if (!uuid) {
            uuid = _generateRandomUuid();
            saveToLocalStorage(DEV_UUID_KEY, uuid);
        } // store to the window object. it makes it easier to acccess throughout the app


        window.deviceUuid = uuid;
        return uuid;
    }

    function _generateRandomUuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0,
                v = c === 'x' ? r : r & 0x3 | 0x8;
            return v.toString(16);
        });
    }

    /**
     * Returns a device info that suits for this webinterface. Could be that it also lists the type of device/Browser
     * that this webinterface is running on.
     * @returns {string}
     */


    function getDeviceInfo() {
        return "Webinterface / " + navigator.platform + " " + navigator.vendor + " " + navigator.userAgent;
    } // ------------------------------------------------------------------------------------------------------------


    function showError(xhrObj) {
        unblockInput();
        var parser = new DOMParser(),
            resText = parser.parseFromString(xhrObj.responseText, "text/xml");

        switch (xhrObj.status) {
            case ErrorCodes.UNAUTHORIZED:
                _showUnauthorized(resText);

                break;

            case ErrorCodes.ACCESS_BLOCKED:
                _showAccessBlocked(resText);

                break;

            case ErrorCodes.NO_PERMISSION:
                alert(Translator.localize("miniserver.no-permission.message"));
                break;

            case ErrorCodes.UNAVAILABLE:
                alert(Translator.localize("pre-load-login.miniserver-unavailable"));
                break;

            case ErrorCodes.MINISERVER_OOS:
                if (xhrObj.responseText === "Miniserver Updating") {
                    alert(Translator.localize("update.installing"));
                } else {
                    alert(Translator.localize("miniserver.waiting.rebooting"));
                }

                break;

            default:
                alert(xhrObj.statusText);
                break;
        }

        clearCredentials();
    }

    function _showUnauthorized(xmlResponse) {
        var pwChangeDurationMsg;

        if (xmlResponse) {
            var lastChangedTimeElem = xmlResponse.getElementsByTagName("timestamp");

            if (lastChangedTimeElem && lastChangedTimeElem.length > 0 && parseInt(lastChangedTimeElem[0].innerHTML) > 0) {
                var changedOn = new Date(parseInt(lastChangedTimeElem[0].innerHTML) * 1000),
                    now = new Date(),
                    showLastChanged = now.getTime() - changedOn.getTime() <= 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds

                if (showLastChanged) {
                    pwChangeDurationMsg = Translator.localize('pre-load-login.last-change-duration', {
                        duration: changedOn.toLocaleString()
                    }) + ".\n";
                }
            }
        }

        var msg = Translator.localize("pre-load-login.invalide-credentials") + "\n" + (pwChangeDurationMsg ? pwChangeDurationMsg : "") + Translator.localize("pre-load-login.lock-warning");
        alert(msg);
    }

    function _showAccessBlocked(xmlResponse) {
        var text = Translator.localize('pre-load-login.too-many-wrong-logins');
        var blockedTime;

        if (xmlResponse) {
            var blockedTimeElem = xmlResponse.getElementsByTagName("remaining");

            if (blockedTimeElem && blockedTimeElem.length > 0) {
                blockedTime = parseInt(blockedTimeElem[0].innerHTML);
            }
        }

        if (blockedTime) {
            text += " (" + blockedTime + "s)";
        }

        alert(text);
    } // To clear the stored user credentials
    // This is crucial if the user enters the wrong credentials


    function clearCredentials() {
        _usr = null;
        _pwd = null;
    }

    function isCloudDNS() {
        return _ipMetaTag.length !== 0;
    }

    function setSavePassword() {
        var checked = _loginForm.autologin.is(":checked");

        _loginForm.form.attr("autocomplete", checked ? "on" : "off");

        _loginForm.username.attr("name", checked ? "username" : new Date().getTime());

        _loginForm.username.attr("id", checked ? "username" : new Date().getTime());

        _loginForm.username.attr("autocomplete", checked ? "on" : "off");

        _loginForm.password.attr("autocomplete", checked ? "on" : "off");

        _loginForm.form.hide().show();

        saveToLocalStorage("autologin", checked);
        _autologin = checked;
    }

    function setAutologin(enabeled) {
        _autologin = enabeled;
        saveToLocalStorage("autologin", _autologin);
    }

    function blockInput() {
        _loginForm.username.attr("disabled", "disabled");

        _loginForm.password.attr("disabled", "disabled");

        _loginForm.connect.attr("disabled", "disabled"); // Adding the indicator


        _loginForm.connect.html('<div class="connect-button__spinner">');
    }

    function unblockInput() {
        _loginForm.connect.text(Translator.localize("miniserver.credentials.establishconn"));

        _loginForm.username.removeAttr("disabled");

        _loginForm.password.removeAttr("disabled");

        _loginForm.connect.removeAttr("disabled");
    }

    function isRecommendedBrowser() {
        var userAgent = navigator.userAgent; // window.navigator.standalone is for Mobile Safari, it is true for webclips

        return userAgent.indexOf("Chrome") !== -1 || userAgent.indexOf("Safari") !== -1 || window.navigator.standalone !== undefined || userAgent.indexOf("Firefox") !== -1;
    }

    function canAutologin() {
        var userAgent = navigator.userAgent;
        return userAgent.indexOf("Chrome") !== -1;
    }

    if (!isRecommendedBrowser()) {
        alert(Translator.localize("pre-load-login.not-recogmended-browser"));
    }

    function saveToLocalStorage(key, value) {
        try {
            localStorage.setItem(key, value);
            return true;
        } catch (e) {
            console.info("Webinterface is running in PrivateMode, or LocalStorage is unavailable");
            return false;
        }
    }

    function readFromLocalStorage(key) {
        try {
            return localStorage[key];
        } catch (e) {
            console.info("Webinterface is running in PrivateMode, or LocalStorage is unavailable");
        }
    }

    /**
     * Will try to look up a device from the archive using the serial number. The archive in the local storage might
     * contain info that can be useful.
     * @param snr
     * @returns {*} the Miniserver object from the archive.
     */


    function getMiniserverFromArchive(snr) {
        var res,
            archive = JSON.parse(readFromLocalStorage("LoxArchive.json") || "{}");

        if (archive && archive.deviceList) {
            res = archive.deviceList[snr];
        }

        return res;
    }

    /**
     * Validates if the public key (pk) must be re-fetched or not,
     * if no public key or version is been passed to the function the key will be forcefully fetched
     * @param pk
     * @param version
     * @returns {boolean}
     */


    function shouldForceFetchPk(pk, version) {
        if (!pk || !version) {
            return true;
        } // IMPORTANT:
        //  • Replace every whitespace character (\s) with nothing to be able to check if this is the development public key!
        //  • Also replace every whitespace character (\s) of the DEV_PUBLIC_KEY to match with the tmpPk


        var tmpPk = pk.replace(/\s/g, ""),
            // Use tmpPk to not manipulate the existing public key
            tmpDevPk = DEV_PUBLIC_KEY.replace(/\s/g, "");
        return tmpPk === tmpDevPk && LxCommunicator.shouldResetDevToken(version);
    }

    String.prototype.endsWith = function (suffix) {
        return this.indexOf(suffix, this.length - suffix.length) !== -1;
    }; // Register for the submit event and do our own stuff here!


    _loginForm.form.submit(function (e) {
        checkLogin(_usr, _pwd);
        return !1;
    });

    if (_autologin) {
        // We need to call .each, otherwise we cant get the value of the input element or we can't trigger the submit event
        // This is the autologin stuff, we wait 200ms then we get the credentials and check if we got them and if we should do the autologin,
        // We then fire the autologin event manually
        setTimeout(function () {
            _loginForm.form.children().each(function () {
                var elem = $(this);

                if (elem.is(_loginForm.username)) {
                    _usr = elem.val();
                }

                if (elem.is(_loginForm.password)) {
                    _pwd = elem.val(); // pwd is an empty string when the user reloading the website via the URL-Bar
                    // So if the focus is not set to the Website itself we cant get the password

                    if (_usr && (_pwd || providedToken || providedTrustToken)) {
                        _loginForm.form.each(function () {
                            $(this).submit();
                        });
                    } else {
                        _usr = null;
                        _pwd = null;
                    }
                }
            });
        }, 200);
    }

    return {
        checkLogin: checkLogin,
        setSavePassword: setSavePassword
    };
}();
