'use strict';

module.exports = function LxCommunicator() {
    let JSEncrypt = require("jsencrypt").default,
        CryptoJS = require("crypto-js"),
        TOKEN_CFG_VERSION = "8.4.5.10",
        ENCRYPTION_CFG_VERSION = "8.1.10.14",
        DEV_PK_RESET_VERSION = "10.2.3.13",
        AUTO_HTTPS_REDIRECT_VERSION = "12.1.7.21",
        SHA256_CFG_VERSION = "10.4.0.0",
        JWT_SUPPORT = "10.1.12.11",
        AUTH_CMD = "jdev/sps/LoxAPPversion3",
        SALT_CMD = "jdev/sys/getkey2/",
        TOKEN_CMD = "jdev/sys/gettoken/",
        JWTOKEN_CMD = "jdev/sys/getjwt/",
        PUBKEY_CMD = "jdev/sys/getPublicKey",
        FENC_CMD = "jdev/sys/fenc/",
        HASH_ALG = {
            SHA1: "SHA1",
            SHA256: "SHA256"
        },
        public_key,
        DEBUG = false;

    /**
     * Launches an http request to the URL provided. The (hashed) authentication has to be provided via data if needed.
     * @param url
     * @param cmd
     * @param data  json object with arguments that will be added to the request
     * @param rawResponse   checkResponse won't be called on the rsp if this is true.
     * @result {Promise}    a promise that resolves with the result
     */

    function request(url, cmd, data, rawResponse) {
        var rq = {
                url: url + cmd,
                data: data,
                dataType: rawResponse ? "html" : "json",
                cache: false
            },
            res,
            ajax;
        DEBUG && console.log("App->Miniserver HTTP: " + cmd);
        ajax = $.ajax(rq);
        res = ajax.then(function (resObj) {
            DEBUG && console.log("Miniserver->App HTTP: " + JSON.stringify(resObj));
            return resObj;
        }); // This prevents the browser to show the authentication popup if 401 is returned form the Miniserver

        ajax.error(function (xhr, statusText) {// Its enought to just add the error function, we don't need to do anything.
        });

        if (rawResponse) {
            return res;
        } else {
            return res.then(_checkResponse);
        }
    }

    /**
     * Just like request, but it returns only the results value parsed as JSON.
     * @param url
     * @param cmd
     * @param data
     * @result {Promise}    a promise that resolves with the results value, represented as parsed json.
     */


    function requestValue(url, cmd, data) {
        return request(url, cmd, data).then(function (json) {
            return _retrieveValue(json);
        });
    }

    /**
     * Will launch an authentication request based on the username and password provided. The credetnials will be hashed
     * using the otSalt provided. The request will NOT be encrypted.
     * @param url
     * @param username
     * @param password
     * @param otSalt        the onetime salt to use for authenticating
     * @param currVersion   the current version running on the Miniserver
     * @returns {*}
     */


    function authViaPassword(url, username, password, otSalt, currVersion) {
        var hashAlg = _versionCheck(SHA256_CFG_VERSION, currVersion) ? HASH_ALG.SHA256 : HASH_ALG.SHA1,
            authData = {
                auth: CryptoJS["Hmac" + hashAlg](username + ":" + password, _hexToString(otSalt)).toString(),
                user: username
            };
        return request(url, AUTH_CMD, authData);
    }

    /**
     * Will return whether or not a Miniserver running on the version passed in supports tokens.
     * @param currVersion   the current version running on the Miniserver
     * @returns {boolean}   true if it supports tokens.
     */


    function supportsTokens(currVersion) {
        return _versionCheck(TOKEN_CFG_VERSION, currVersion);
    }

    /**
     * Will return whether or not a Miniserver running on the version passed in supports encryption.
     * @param currVersion   the current version running on the Miniserver
     * @returns {boolean}   true if it supports encryption.
     */


    function supportsEncryption(currVersion) {
        return _versionCheck(ENCRYPTION_CFG_VERSION, currVersion);
    }

    /**
     * Will return whether or not a Miniserver running on the version passed in supports development public key reset.
     * @param currVersion   the current version running on the Miniserver
     * @returns {boolean}   true if it supports development public key reset.
     */


    function shouldResetDevToken(currVersion) {
        return _versionCheck(DEV_PK_RESET_VERSION, currVersion);
    }

    /**
     * Will return weather or not the Miniserver supports automatic HTTPS redirects
     * @param currVersion   the current version running on the Miniserver
     * @returns {boolean}   true if it supports automatic HTTPS redirects
     */


    function supportsHTTPSRedirect(currVersion) {
        return _versionCheck(AUTO_HTTPS_REDIRECT_VERSION, currVersion);
    }

    /**
     * Acquire the public key for this Miniserver
     * @param url
     */


    function getPublicKey(url) {
        if (public_key) {
            return $.when(public_key);
        } else {
            return requestValue(url, PUBKEY_CMD).then(function (pubKey) {
                public_key = pubKey;
                return pubKey;
            });
        }
    }

    /**
     * Once this method has been called encryption can be used.
     * @param pubKey
     */


    function setPublicKey(pubKey) {
        public_key = pubKey;
    }

    /**
     * Will request a token using the username and password provided. The password will be transmitted both hashed and
     * encrypted.
     * @param url
     * @param username
     * @param password
     * @param type          the token type (e.g. 0 = short lived for WI, 1 = long lived for apps, ..)
     * @param deviceUuid    id that is used on the miniserver to uniquely identify this device. should remain the same over time
     * @param deviceInfo    userfriendly device info that will be used to display who currently has tokens.
     * @param msVersion    miniserver Version
     * @returns {*}
     */


    function requestToken(url, username, password, type, deviceUuid, deviceInfo, msVersion) {
        var pwHash, hash, cmd;
        return _requestTokenSalts(url, username).then(function (saltObj) {
            // create a SHA1 or SHA256 hash of the (salted) password
            pwHash = CryptoJS[saltObj.hashAlg](password + ":" + saltObj.salt).toString();
            pwHash = pwHash.toUpperCase(); // hash with user and otSalt

            hash = _otHash(username + ":" + pwHash, saltObj.oneTimeSalt, saltObj.hashAlg); // build up the token command

            cmd = _getTokenCmd(hash, username, type, deviceUuid, deviceInfo, msVersion); // launch it

            return lxEncRequestValue(url, cmd);
        });
    }


    function requestWithJwtToken(url, username, token, cmd, raw = false) {
        let tokenHash,
            fullCmd;

        return _requestTokenSalts(url, username).then(saltObj => {
            tokenHash = _otHash(token, saltObj.oneTimeSalt, "SHA256");
            fullCmd = cmd + "?autht=" + tokenHash + "&user=" + username;
            return lxEncRequestValue(url, fullCmd, raw);
        });
    }


    /**
     * Fires an encrypted request which does not require a token, but uses a user+pass combination.
     * @param url       target (protocol://host:port/)
     * @param username  the user to use
     * @param password  the password (plain)
     * @param cmd       cmd to be sent
     * @param raw       if true, the response is not parsed (=no LL response)
     * @returns {*}
     */
    function requestWithPassword(url, username, password, cmd, raw = false) {
        let pwHash, hash;
        return _requestTokenSalts(url, username).then(function (saltObj) {
            // create a SHA1 or SHA256 hash of the (salted) password
            pwHash = CryptoJS[saltObj.hashAlg](password + ":" + saltObj.salt).toString();
            pwHash = pwHash.toUpperCase(); // hash with user and otSalt

            hash = _otHash(username + ":" + pwHash, saltObj.oneTimeSalt, saltObj.hashAlg); // build up the token command

            const fullCmd = cmd + "?auth=" + hash;
            return lxEncRequestValue(url, fullCmd, true);
        })
    }


    // ----------------------------------------------------------------------------------------------------
    // -----                                     Private Methods                                    -------
    // ----------------------------------------------------------------------------------------------------

    /**
     * Takes a required and a current version and will return true or false depending on if the version meets this criteria
     * @param required
     * @param current
     * @returns {boolean}   true if the current is equal or greater to the required version.
     */


    function _versionCheck(required, current) {
        var _partify = function _partify(versionString) {
            var prts = [];
            versionString.split(".").forEach(function (prt) {
                prts.push(parseInt(prt));
            });
            return prts;
        };

        var requiredV = _partify(required),
            currV = _partify(current),
            isOkay = true;

        for (var i = 0; i < requiredV.length && i < currV.length && isOkay; i++) {
            if (requiredV[i] < currV[i]) {
                // if the one of the first parts is smaller, the rest no longer needs to be checked.
                isOkay = true;
                break;
            } else {
                isOkay = requiredV[i] <= currV[i];
            }
        }

        return isOkay;
    }

    /**
     * Will create a oneTime hash of the payload using the salt provided
     * @param payload
     * @param otSalt
     * @param hashAlg
     * @returns {string}
     * @private
     */


    function _otHash(payload, otSalt, hashAlg) {
        var msg = CryptoJS.enc.Utf8.parse(payload);
        var k = CryptoJS.enc.Hex.parse(otSalt);
        var hash = CryptoJS["Hmac" + hashAlg](msg, k);
        return hash.toString(CryptoJS.enc.Hex);
    }

    /**
     * Will build up the get token command including all the infos required for it.
     * @param hash
     * @param user
     * @param permission
     * @param uuid
     * @param info
     * @param msVersion
     * @returns {string}
     * @private
     */


    function _getTokenCmd(hash, user, permission, uuid, info, msVersion) {
        var encInfo = encodeURIComponent(info).replace(/\//g, " "),
            tokenCmd;

        if (_versionCheck(JWT_SUPPORT, msVersion)) {
            tokenCmd = JWTOKEN_CMD;
        } else {
            tokenCmd = TOKEN_CMD;
        }

        return tokenCmd + hash + "/" + user + "/" + permission + "/" + uuid + "/" + encInfo;
    }

    /**
     * Will request the salts required to acquire a token
     * @param url
     * @param username
     */


    function _requestTokenSalts(url, username) {
        var cmd = SALT_CMD + username;
        return lxEncRequestValue(url, cmd).then(function (result) {
            return {
                oneTimeSalt: result.key,
                salt: result.salt,
                hashAlg: result.hashAlg || HASH_ALG.SHA1
            };
        });
    }

    /**
     * Will analyize the responses status, as an error might respond with HTTP-Status 200, but inside it reveals an error.
     * @param resp
     * @returns {*}
     */


    function _checkResponse(resp) {
        var status = parseInt(resp.LL.Code || resp.LL.code);

        if (status >= 200 && status < 400) {
            return resp;
        } else {
            throw new Error(resp);
        }
    } // Encryption

    /**
     * Will launch an encrypted request to the Miniserver provided via the url.
     * @param url
     * @param cmd
     * @param [raw] don't parse or verify a code within the result.
     * @returns {*}
     */


    function lxEncRequestValue(url, cmd, raw = false) {
        var encObj, decrResponse;
        DEBUG && console.log("App->Miniserver HTTP (enc): " + cmd);
        return getPublicKey(url).then(function (pKey) {
            encObj = encryptRequest(cmd, pKey);
            return request(url, encObj.encCmd, null, true); // don't check the response, it's encrypted.
        }).then(function decryptResponse(rsp) {
            try {
                decrResponse = _aesDecryptedUtf8(rsp, encObj.aesKey, encObj.aesIV);
                decrResponse = JSON.parse(decrResponse);
            } catch (ex) {
                // nothing to do.
                decrResponse = rsp;
            }

            DEBUG && console.log("Miniserver->App HTTP: (enc): " + JSON.stringify(decrResponse));

            if (raw) {
                return decrResponse;
            }

            // perform response check after decrypting it!
            decrResponse = _checkResponse(decrResponse);

            // return the value itself.
            return _retrieveValue(decrResponse);
        });
    }

    /**
     * Takes the steps as documented in the Loxone API documentation on encrypted HTTP Requests
     * @param cmd
     * @param pubKey
     * @returns {*} an object containing the aesKey, aesIV, the salt and the encrypted cmd
     */


    function encryptRequest(cmd, pubKey) {
        var payload,
            rsaCipher,
            aesCipher,
            result = {}; // assumes step 1 & 2 of the documentation have already been passed
        // 3. Generate a random salt, hex string (length may vary, e.g. 2 bytes) -> {salt}

        result.salt = _hexSalt(); // 4. Prepend the salt to the actual message “salt/{salt}/{cmd}” ->{plaintext}

        payload = "salt/" + result.salt + "/" + cmd; // 5. Generate a AES256 key -> {key} (Hex)

        result.aesKey = _aesKey(); // 6. Generate a random AES iv (16 byte) -> {iv} (Hex)

        result.aesIV = _aesIV(); // 7. Encrypt the {plaintext} with AES {key} + {iv} -> {cipher} (Base64)

        aesCipher = _aesEncryptedBase64(payload, result.aesKey, result.aesIV); // 8.  Prepare the command-> {encrypted-command}, in the WI-Loader, it's always full encryption
        // don't encodeURIComponent() the aesCipher, the CloudDNS can't handle it

        payload = FENC_CMD + aesCipher; // 9. RSA Encrypt the AES key+iv with the {publicKey} -> {session-key} (Base64)

        rsaCipher = _rsaEncrypt(result.aesKey + ":" + result.aesIV, pubKey); // 10.  Append the session key to the {encrypted-command} -> {encrypted-command}
        // don't encodeURIComponent() the rsaCipher, the CloudDNS can't handle it

        result.encCmd = payload + "?sk=" + rsaCipher;
        return result; // steps 13, 14, 15 will be performed on the outside.
    } // Crypto


    function _hexSalt() {
        return CryptoJS.lib.WordArray.random(2).toString(CryptoJS.enc.Hex);
    }

    function _aesIV() {
        return CryptoJS.lib.WordArray.random(128 / 8).toString(CryptoJS.enc.Hex);
    }

    function _aesKey() {
        var salt = CryptoJS.lib.WordArray.random(128 / 8),
            hexId = CryptoJS.enc.Hex.parse(_generateRandomHexId()),
            key = hexId.toString();
        return CryptoJS.PBKDF2(key, salt, {
            keySize: 256 / 32,
            iterations: 50
        }).toString(CryptoJS.enc.Hex);
    }

    /**
     * Returns a random hexdecimal id string.
     * @returns {string}
     * @private
     */


    function _generateRandomHexId() {
        return CryptoJS.lib.WordArray.random(36).toString(CryptoJS.enc.Hex);
    }

    /**
     * AES encrypts the plaintext with the key and iv
     * @param payload
     * @param key       hex string
     * @param iv        hex string
     * @returns {string} Base64
     */


    function _aesEncryptedBase64(payload, key, iv) {
        var encrypted = CryptoJS.AES.encrypt(payload, CryptoJS.enc.Hex.parse(key), {
            iv: CryptoJS.enc.Hex.parse(iv),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.ZeroPadding
        });
        return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
    }

    /**
     * AES decrypts the base64 ciphertext with the key and iv
     * @param ciphertext_base64
     * @param key_hex
     * @param iv_hex
     * @returns {string} utf8
     */


    function _aesDecryptedUtf8(ciphertext_base64, key_hex, iv_hex) {
        ciphertext_base64 = ciphertext_base64.replace(/\n/, "");

        var ciphertext_hex = _checkBlockSize(_b64ToHex(ciphertext_base64), 16); // the blockSize is 16


        var cipherParams = CryptoJS.lib.CipherParams.create({
            ciphertext: CryptoJS.enc.Hex.parse(ciphertext_hex)
        });
        var decrypted = CryptoJS.AES.decrypt(cipherParams, CryptoJS.enc.Hex.parse(key_hex), {
            iv: CryptoJS.enc.Hex.parse(iv_hex),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.ZeroPadding
        });
        return decrypted.toString(CryptoJS.enc.Utf8);
    }

    /**
     * checks blockSize and fills up with 0x00 if the hex string has an incorrect length
     * Bug in old Miniserver Versions!
     * https://www.wrike.com/open.htm?id=143296929
     * @param hexStr
     * @param blockSize
     * @returns hexStr
     */


    function _checkBlockSize(hexStr, blockSize) {
        if (hexStr.length % blockSize > 0) {
            hexStr = hexStr + new Array(blockSize - hexStr.length % blockSize + 1).join('0');
        }

        return hexStr;
    }

    /**
     * RSA encrypts the plaintext with the given public key
     * @param plaintext
     * @param publicKey
     * @returns {string} base64
     */


    function _rsaEncrypt(plaintext, publicKey) {
        var encrypt = new JSEncrypt();
        encrypt.setPublicKey(publicKey);
        return encrypt.encrypt(plaintext);
    }

    function _hexToString(d) {
        var r = '',
            m = ('' + d).match(/../g),
            t;

        while (t = m.shift()) {
            r += String.fromCharCode('0x' + t);
        }

        return r;
    }

    function _b64ToHex(b64) {
        return CryptoJS.enc.Base64.parse(b64).toString(CryptoJS.enc.Hex);
    }

    /**
     * Helper method that retrieves & parses the value from a JSON response from the Miniserver.
     * @param json
     * @private
     */


    function _retrieveValue(json) {
        try {
            return JSON.parse(json.LL.value.replace(/\'/g, '"'));
        } catch (ex) {
            return json.LL.value;
        }
    }

    return {
        getPublicKey: getPublicKey,
        setPublicKey: setPublicKey,
        supportsTokens: supportsTokens,
        supportsEncryption: supportsEncryption,
        supportsHTTPSRedirect: supportsHTTPSRedirect,
        shouldResetDevToken: shouldResetDevToken,
        authViaPassword: authViaPassword,
        requestToken: requestToken,
        requestValue: requestValue,
        requestWithJwtToken: requestWithJwtToken,
        requestWithPassword: requestWithPassword,
    };
}();
